@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap');

* {
    font-family: "Karla", sans-serif;
    scroll-behavior: smooth;
    user-select: none;
    color: #fff;
}

body {
    background-color: #0a0b0d;
    /*background-color: #e7e7e7;*/
    overflow-x: hidden;
}

p, h1, h2, h3 {
    margin: 0;
}

.hyperlink {
    @apply text-blue-500 hover:text-blue-600 underline underline-offset-2;
}

::selection {
    @apply bg-blue-400;
}

::-webkit-scrollbar {
    width: 1rem;
}

::-webkit-scrollbar-thumb {
    background: #2b2b2b;
    border-radius: 9999px;
    border: 0.25rem solid transparent;
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(66 66 66 / 0.815);
    border-radius: 9999px;
    border: 0.25rem solid transparent;
    background-clip: padding-box;
}

/*#nav {
    box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 30%), 0px 1px 3px 1px rgb(60 64 67 / 15%);
}*/

#navHeader {
    box-shadow: 1px 0 3px 2px rgb(0 0 0 / 30%);
}

#navDrawer {
    box-shadow: 1px 0 3px 2px rgb(0 0 0 / 50%);
}

.separator-hr {
    display: flex;
    align-items: center;
    text-align: center;
    height: 1rem;
    color: #ccc;
}

.separator-hr::before {
    margin-right: 0.5em;
    border-radius: 1rem;
}

.separator-hr::after {
    margin-left: 0.5em;
    border-radius: 1rem;
}

.separator-hr::after, .separator-hr::before {
    content: "";
    -webkit-flex: 1 1;
    -moz-box-flex: 1;
    flex: 1 1;
    border-bottom: 1px solid #fff;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
